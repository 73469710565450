import "./Background.css";


export default function AnimatedBackground() {
    return (
        <div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
        </div>
    )
 }