import { Container, useMediaQuery, Flex, Text, Grid, GridItem, Image, Link, Button, Icon } from "@chakra-ui/react";
import React, { useRef } from 'react';

import ModalWindowInformation from "./ModalWindowInformation";
import ModalWindowPricing from "./ModalWindowPricing";
import ModalWindowTermAndConditions from "./ModalWindowTermAndConditions";

import { RiRobot2Fill } from "react-icons/ri";



export default function Navbar() {
    const [isLargerThanMobile] = useMediaQuery('(min-width: 768px)');
    const modalRefInformation = useRef();
    const modalRefPricing = useRef();
    const modalRefTermAndConditions = useRef();

    return (
        <Container maxWidth={"95vw"} py={"2"}>
            <Flex flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Grid gridTemplateColumns={isLargerThanMobile ? 'repeat(6, 1fr)' : 'repeat(1, 1fr)'} alignItems={"center"}>
                    <GridItem>
                        <Link href={"/"}>
                            <Image boxSize='5vw' src="logo512x512.png" alt="logo" />
                        </Link>
                    </GridItem>
                    <GridItem>
                        <Button onClick={() => modalRefInformation.current.onOpen()} variant={"ghost"}>
                            <Text color={"white"}>Information</Text>
                        </Button>
                    </GridItem>
                    <GridItem>
                    <Button onClick={() => modalRefPricing.current.onOpen()} variant={"ghost"}>
                            <Text color={"white"}>Pricing</Text>
                        </Button>
                        </GridItem>
                        <GridItem>
                        <Button onClick={() => modalRefTermAndConditions.current.onOpen()} variant={"ghost"}>
                            <Text color={"white"}>Term and Conditions</Text>
                        </Button>
                        </GridItem>
                </Grid>
                <Flex flexDirection={"row"} alignItems={"center"}>
                    <Button variant='outline' onClick={event =>  window.location.href='https://t.me/solanatokenmanager_bot'}><Icon color={"white"} as={RiRobot2Fill} /> <Text color={"white"}>⠀Start creating!</Text></Button>
                </Flex>
            </Flex>
            <ModalWindowInformation ref={modalRefInformation} />
            <ModalWindowPricing ref={modalRefPricing} />
            <ModalWindowTermAndConditions ref={modalRefTermAndConditions} />
        </Container>
    )
}
