import './App.css';

import Navbar from "./Navbar"
import Body from "./Body"
import AnimatedBackground from "./Background"
import Footer from "./Footer"

import { ChakraProvider } from '@chakra-ui/react'

require('@solana/wallet-adapter-react-ui/styles.css');



function App() {
  return (

    <ChakraProvider>
      <div className="App" >
        <div className="animated_background">
          <Navbar />
          <Body />
          <AnimatedBackground />
          <Footer />
        </div>
      </div>
    </ChakraProvider>

  );
}


export default App;
