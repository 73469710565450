import './Scrollbar.css';

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Text, useDisclosure } from '@chakra-ui/react'
import { useState, useImperativeHandle, forwardRef } from "react";


const ModalWindowPricing = forwardRef((props, ref) => {
    const { isOpen, onOpen, onClose } = useDisclosure({id: 'information'})

    const OverlayOne = () => (
        <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px)'
        />
    )
    const [overlay] = useState(<OverlayOne />)

    useImperativeHandle(ref, () => ({
        onOpen: () => {
          onOpen();
        }
      }));

    return (
        <>
            <Modal isCentered isOpen={isOpen} onClose={onClose} scrollBehavior={"inside"} size={'sm'} >
                {overlay}
                <ModalContent backgroundColor={"rgb(1, 1, 1, 0.1)"} >
                    <ModalHeader color={"white"} textAlign={'center'}>Pricing</ModalHeader>
                    <ModalCloseButton color={"white"} />
                    <ModalBody className="scroller">
                        <Text color={"white"}>
🤔 What is the price to create a token?<br/>
⠀⠀➤ SOL fee + ~0.03 + 0.03.<br/><br/>
🤔 What is the price to mint a token?<br/>
⠀⠀➤ SOL fee + ~0.01 + 0.02.<br/><br/>
🤔 What is the price to revoke authority?<br/>
⠀⠀➤ SOL fee + ~0.01 + 0.02.<br /><br/>
🤔 What is the price to create Open Market?<br/>
⠀⠀➤ SOL fee + ~0.3 + 0.05.<br /><br/>
🤔 What is the price to create LP (Raydium)?<br/>
⠀⠀➤ SOL fee + ~0.4 + 0.05.<br /><br/>
🤔 What is the price to burn LP?<br/>
⠀⠀➤ SOL fee + ~0.01 + 0.05.<br /><br/>
🤔 What is the price to remove LP?<br/>
⠀⠀➤ SOL fee + ~0.01 + 0.05.
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
});


ModalWindowPricing.displayName = 'PricingModal';
export default ModalWindowPricing;
