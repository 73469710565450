import './Scrollbar.css';

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Text, useDisclosure } from '@chakra-ui/react'
import { useState, useImperativeHandle, forwardRef } from "react";


const ModalWindowTermAndConditions = forwardRef((props, ref) => {
    const { isOpen, onOpen, onClose } = useDisclosure({id: 'information'})

    const OverlayOne = () => (
        <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px)'
        />
    )
    const [overlay] = useState(<OverlayOne />)

    useImperativeHandle(ref, () => ({
        onOpen: () => {
          onOpen();
        }
      }));

    return (
        <>
            <Modal isCentered isOpen={isOpen} onClose={onClose} scrollBehavior={"inside"} size={'4xl'} >
                {overlay}
                <ModalContent backgroundColor={"rgb(1, 1, 1, 0.1)"} >
                    <ModalHeader color={"white"} textAlign={'center'}>Term and Conditions<br />STM bot: Token Creation Bot for SOL Chain</ModalHeader>
                    <ModalCloseButton color={"white"} />
                    <ModalBody className="scroller">
                        <Text color={"white"}>
This document presents a description of a telegram bot designed for the SOL chain. The purpose of this bot is to facilitate the creation of tokens on the SOL blockchain network. It is important to note that this bot has been developed solely for testing purposes and does not store any user data. Furthermore, the bot does not assume any responsibility for the tokens created or the actions taken by the users, as the sole responsibility lies with the users themselves.<br /><br />

1. Introduction<br />
STM bot is a software application designed to streamline the process of creating tokens on the SOL blockchain. With the increasing popularity of blockchain technology and the SOL chain in particular, there is a growing need for tools that simplify token creation. This bot aims to address this need by providing a user-friendly interface and automated functionality for token creation.<br /><br />

2. Functionality<br />
The Token Creation Bot offers the following key features:<br />

• 2.1 User-Friendly Interface<br />
The bot provides an intuitive and easy-to-use interface for users to interact with. It guides users through the process of token creation, ensuring a seamless experience.<br />

• 2.2 Token Creation<br />
Users can specify the token parameters, such as token name, symbol, total supply, and decimal places, through the bot's interface. The bot then leverages the SOL chain's functionality to create the token according to the provided specifications.<br />

• 2.3 Testing Environment<br />
As mentioned earlier, this bot is primarily intended for testing purposes.<br /><br />

3. Data Privacy and Security<br />
STM bot follows strict privacy guidelines. It does not store any user data, including personal information or wallet addresses. Users are responsible for managing their own wallets and private keys, ensuring the security of their assets.<br /><br />

4. Disclaimer<br />
It is essential to understand that STM bot is a testing tool and not intended for production use. While the bot strives to provide accurate and reliable functionality, it is subject to limitations and may contain bugs or errors. Users should exercise caution and conduct their own due diligence when using the bot for token creation.<br /><br />

The bot and its developers bear no responsibility for the tokens created or the actions taken by users. Users are solely responsible for complying with applicable laws and regulations, ensuring the legitimacy of their token creation activities, and resolving any disputes that may arise.<br /><br />

5. Conclusion<br />
STM bot offers a convenient and efficient solution for testing token creation on the SOL blockchain. By providing a user-friendly interface and automated functionality, the bot aims to empower users to experiment with token creation while recognizing their sole responsibility for the tokens created and actions taken. It is essential to recognize the limitations and testing nature of this bot and exercise caution when using it.<br /><br />

Please note that this document serves as a description of the bot's rights and functionalities and should not be considered as investment advice or endorsement of any token creation activity.
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
});


ModalWindowTermAndConditions.displayName = 'TermAndConditionsModal';
export default ModalWindowTermAndConditions;
