import { Container, useMediaQuery, Image, Flex, Stack, Text, Grid, GridItem, Divider, Icon, Button, Link } from "@chakra-ui/react";
import { IoArrowDown } from "react-icons/io5";
import { RiRobot2Fill } from "react-icons/ri";
import { FaTelegramPlane } from "react-icons/fa";


export default function Body() {
    const [isLargerThanMobile] = useMediaQuery('(min-width: 768px)');

    return (
    <Container maxW="100vw" py={'5vh'}>
        <Flex justifyContent={"center"} alignItems={"center"}>
          <Grid templateColumns={isLargerThanMobile ? 'repeat(1, 1fr)' : 'repeat(1, 1fr)'} gap={'4vw'}>
            <GridItem px={'3vw'}>
              <Flex justifyContent={"center"} alignItems={"center"}>
                <Stack>
                  <Text textAlign={'center'} fontSize='2xl' color={"white"}>Become a creator with <Link color='yellow.100' href='https://t.me/solanatokenmanager_bot'><u>STM bot</u></Link>!</Text>
                  <br /><Divider /><br />
                  <Text fontSize='md' color={"white"}>The easiest, fastest and cheapest token creation bot on SOL!</Text>
                  <br /><Divider /><br />
                  <Text fontSize={"md"} color={"white"}>Navigation:</Text>
                  <Grid alignItems={"center"} justifyContent={"center"} templateColumns='repeat(1, 1fr)' gap={3} py={'1vh'}>
                    <Text color={"white"}>Bot TG</Text>
                  </Grid>
                  <Button variant='outline' onClick={event =>  window.location.href='https://t.me/solanatokenmanager_portal'}><Icon color={"white"} as={FaTelegramPlane} /></Button>
                </Stack>
              </Flex>
            </GridItem>
          </Grid>
          
        </Flex>
        <br /><br />
        <Icon w={8} h={8} color={"white"} as={IoArrowDown} />
        <br /><br /><br /><br />
        <Stack justifyContent={"center"} alignItems={"center"}>
        <Text fontSize={"6xl"} color={"white"}>Features</Text>
        <Grid templateColumns={isLargerThanMobile ? 'repeat(5, 1fr)' : 'repeat(1, 1fr)'} gap={'1vw'}>
        <GridItem px={'1vw'}><Text fontSize={"md"} color={"white"}><b>Price</b><br /></Text><Text fontSize={"sm"} color={"white"}>The cheapest prices in the world! So far, there is no tool cheaper than this bot!</Text></GridItem>
        <GridItem px={'1vw'}><Text fontSize={"md"} color={"white"}><b>Comfort</b><br /></Text><Text fontSize={"sm"} color={"white"}>Replacing 4 different websites with one bot! Are you tired of keeping a bunch of sites in your head all the time?</Text></GridItem>
        <GridItem px={'1vw'}><Text fontSize={"md"} color={"white"}><b>Speed</b><br /></Text><Text fontSize={"sm"} color={"white"}>Creation speed at the highest level! It even rents its own node for your convenience!</Text></GridItem>
        <GridItem px={'1vw'}><Text fontSize={"md"} color={"white"}><b>Stable</b><br /></Text><Text fontSize={"sm"} color={"white"}>The bot does not fall! The bot follows Facebook's achievement of never crashing. The developer modifies the bot every day!</Text></GridItem>
        <GridItem px={'1vw'}><Text fontSize={"md"} color={"white"}><b>Unique</b><br /></Text><Text fontSize={"sm"} color={"white"}>You can create an address for a token with a unique beginning, such as the address of this bot's token:<br/><u>STMx</u>zU2W93fhzsMuVfbkDMVyKJj9x2SAVYqRqNBhKAs</Text></GridItem>
        </Grid></Stack>
        <br /><br />
        <Icon w={8} h={8} color={"white"} as={IoArrowDown} />
        <br /><br /><br />
        <Stack justifyContent={"center"} alignItems={"center"}>
        <Text fontSize={"6xl"} color={"white"}>How to use?</Text>
        <Grid templateColumns={'repeat(1, 1fr)'} gap={'1vw'}>
        <GridItem px={'3vw'}><Text fontSize={"2xl"} color={"white"}>1.<br /></Text><Text fontSize={"sm"} color={"white"}>Write to the <Link color='yellow.200' href='https://t.me/solanatokenmanager_bot'><u>bot</u></Link> in private messages <u>/start</u> and read all the information carefully!</Text></GridItem>
        <GridItem px={'3vw'}><Text fontSize={"2xl"} color={"white"}>2.<br /></Text><Text fontSize={"sm"} color={"white"}>Type the command <u>/wallet</u> and import or create a new wallet! To do this, you need to press one of the buttons that the bot will provide and follow the instructions of the bot!</Text></GridItem>
        <GridItem px={'3vw'}><Text fontSize={"2xl"} color={"white"}>3.<br /></Text><Text fontSize={"sm"} color={"white"}>Make sure you have sent enough $SOL to your wallet, you can check the prices with the <u>/pricing</u> command!</Text></GridItem>
        <GridItem px={'3vw'}><Text fontSize={"2xl"} color={"white"}>4.<br /></Text><Text fontSize={"sm"} color={"white"}>Go to the token menu using <u>/token</u> and create your first token using the corresponding button and fill in all the fields following the instructions!</Text></GridItem>
        <GridItem px={'3vw'}><Text fontSize={"2xl"} color={"white"}>5.<br /></Text><Text fontSize={"sm"} color={"white"}>At the end, check that everything is correct and press the confirmation button!</Text></GridItem>
        <GridItem px={'3vw'}><Text fontSize={"2xl"} color={"white"}>6.<br /></Text><Text fontSize={"sm"} color={"white"}>After a short wait, get a confirmation message and type the <u>/token</u> command again to go to the token manager!</Text></GridItem>
        <GridItem px={'3vw'}><Text fontSize={"2xl"} color={"white"}>7.<br /></Text><Text fontSize={"sm"} color={"white"}>Now just press the required buttons and follow the instructions as well!</Text></GridItem>
        <GridItem px={'3vw'}><Text fontSize={"2xl"} color={"white"}>8.<br /></Text><Text fontSize={"sm"} color={"white"}>To create an LP - you first need to create an Open Market and revoke authority freeze!</Text></GridItem>
        <GridItem px={'3vw'}><Text fontSize={"2xl"} color={"white"}>9.<br /></Text><Text fontSize={"sm"} color={"white"}>You can reject any action using <u>/cancel</u>!</Text></GridItem>
        </Grid></Stack>
        <br /><br />
        <Icon w={8} h={8} color={"white"} as={IoArrowDown} />
        <br /><br /><br />
    
        <Text fontSize={"6xl"} color={"white"}>Too hard? Here is video-guide:</Text>
        <Flex justifyContent={"center"} alignItems={"center"}>
  <iframe width="750" height="350" src="https://www.youtube.com/embed/Btj_BqF66_M?si=xUDKxBe_wTa89qvZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></Flex>
<br /><br />
        <Icon w={8} h={8} color={"white"} as={IoArrowDown} />
<br /><br /><br />
        <Stack justifyContent={"center"} alignItems={"center"}>
        <Text fontSize={"6xl"} color={"white"}>Do you want to try?</Text>
        <Button size='lg' variant='outline' onClick={event =>  window.location.href='https://t.me/solanatokenmanager_bot'}><Icon color={"white"} as={RiRobot2Fill} /> <Text fontSize={"2xl"} color={"white"}>⠀Start creating!</Text></Button>
       </Stack>
       
       <br /><br /><br />
    </Container>
  );
}
