import { Box, chakra, Container, Stack, Text } from '@chakra-ui/react'


export default function SmallCentered() {
    return (
      <Box >  
        <Box position={'fixed'} left={'0'} bottom={'2%'} right={'0'} marginBottom={'2vh'} marginTop={'14vh'}>
          <Container as={Stack} maxW={'3xl'} direction={{ base: 'column', md: 'row' }} spacing={4} justify={{ base: 'center'}} align={{ base: 'center'}}>
            <Text opacity={0.25} color={"white"}>© 2024 STM bot. All rights reserved</Text>
          </Container>
        </Box>
      </Box>
    )
  }