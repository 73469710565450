import './Scrollbar.css';

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Text, useDisclosure } from '@chakra-ui/react'
import { useState, useImperativeHandle, forwardRef } from "react";


const ModalWindowInformation = forwardRef((props, ref) => {
    const { isOpen, onOpen, onClose } = useDisclosure({id: 'information'})

    const OverlayOne = () => (
        <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px)'
        />
    )
    const [overlay] = useState(<OverlayOne />)

    useImperativeHandle(ref, () => ({
        onOpen: () => {
          onOpen();
        }
      }));

    return (
        <>
            <Modal isCentered isOpen={isOpen} onClose={onClose} scrollBehavior={"inside"} size={'3xl'} >
                {overlay}
                <ModalContent backgroundColor={"rgb(1, 1, 1, 0.1)"} >
                    <ModalHeader color={"white"} textAlign={'center'}>Information</ModalHeader>
                    <ModalCloseButton color={"white"}/>
                    <ModalBody className="scroller">
                        <Text color={"white"}>
                        STM bot is a comprehensive way to create your own tokens on the SOLANA CHAIN in Telegram. Currently, the bot replicates the functionality of four token creation websites and combines them to make everything as fast and convenient as possible for the user. At the moment, STM bot is the fastest and most affordable way to create a token. The bot's functionality is not free. To regularly update and maintain it, working with the bot requires a minimal fee (0.02-0.05 SOL). Users have access to a unique feature of creating tokens with templates in the name. For example this test token: <u>STMx</u>zU2W93fhzsMuVfbkDMVyKJj9x2SAVYqRqNBhKAs.<br/><br/>
At the moment, STM bot is in the beta testing stage, so if you notice any bugs or would like to suggest improvements to the functionality, please contact the development team.<br/><br/>
The main advantages of using STM bot are:<br/>
•	Maximum affordability due to minimal fees (there is no cheaper alternative available in free access).<br/>
•	Convenient usage on the Telegram platform.<br/>
•	High speed of operations execution through optimization of all processes.<br/>
•	High level of bot stability and access to functionality 24/7.<br/>
•	Regular updates and improvements.<br/><br/>
STM bot not only allows users to create tokens but also enables them to manage their tokens. For example, users can conveniently mint tokens, revoke mint authority, create an open market at a low price (approximately 0.3 SOL without commissions), create LP Pools, burn LP Pools, and withdraw LP Pools for various reasons. And all of this can be done in one place on the Telegram platform with maximum speed for each operation.<br/><br/>
The current functionality of STM bot allows users to create only one token per wallet at a time. To create other tokens, users need to delete the wallet or token and add a new one.<br/><br/>
The developer does not bear responsibility for any cryptocurrency losses during operations with the bot. The STM bot team does not force you to buy or use functionalities, upload your wallets to the bot, etc. All responsibility lies with the users. STM bot is primarily a tool for saving time and effort.

                        </Text>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
});


ModalWindowInformation.displayName = 'InformationModal';
export default ModalWindowInformation;
